<template>
    <div class="page fill-height">
        <v-container class="d-flex flex-column align-center justify-lg-center pb-lg-16 fill-height px-lg-7">

            <v-card :flat="phoneOnly" class="pa-0" :class="{'transparent w-full': phoneOnly}">
                <div class="form-wrapper">
                    <div v-if="pcOnly || loginTeacher" class="d-flex justify-center flex-column py-3 mb-3">
                        <v-card-title class="font-weight-semibold pt-0 d-flex justify-space-between">
                            <div>{{loginTeacher ? 'Teachers Sign In' : 'Welcome Back!'}}</div>
                            <div>
                                <v-btn v-if="loginTeacher" small depressed outlined style="text-transform: none" exact :to="{name: 'login'}">Students Sign In</v-btn>
                            </div>
                        </v-card-title>
                        <v-card-subtitle class="mb-0 pb-0">Login to portal</v-card-subtitle>
                    </div>

                    <v-card-text>
                        <form @submit.prevent="login">
                            <v-text-field placeholder="Enter Your Email" label="Email" name="email" v-model="email" :loading="loading" :disabled="loading" outlined hide-details></v-text-field>
                            <v-text-field placeholder="Enter Your Password" label="Password" outlined name="password" hide-details v-model="password" :loading="loading" :disabled="loading" class="my-5" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassword ? 'text' : 'password'" @click:append="showPassword = !showPassword"></v-text-field>
                            <div class="d-flex justify-space-between align-center mb-4">
                                <div v-if="pcOnly">
                                    <v-checkbox v-model="remember" :disabled="loading" label="Remember Me" class="ma-0" :class="{'details-text': phoneOnly}" hide-details></v-checkbox>
                                </div>
                                <div>
                                    <v-btn v-if="!loginTeacher" text dense to="/reset-password" :disabled="loading" class="forget-password grey--text px-2" :class="{'details-text': phoneOnly}">Forgot Password?</v-btn>
                                </div>
                            </div>
                            <v-btn type="submit" block depressed large :disabled="loading" color="primary" @click.prevent="login">Sign in</v-btn>
                        </form>
                        <div v-if="!loginTeacher" class="text-center medium-text mt-6">
                            Don't have an account ?
                            <v-btn text dense :to="{name: 'register'}" :disabled="loading" class="primary--text px-1">Sign Up</v-btn>

                            <div>
                                <v-divider class="mb-5 mt-2"></v-divider>
                                <v-btn block :to="{name: 'login-teachers'}" depressed>Teachers Sign In</v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </div>
            </v-card>
        </v-container>

        <slot name="footer" />
    </div>
</template>

<script>

// @ is an alias to /src
import GlobalComputed from '@/helpers/global-computed'
import GlobalMethods from '@/helpers/global-methods'
import AppAPI from '@/app-api'

export default {
    name: 'login',
    data(){
        return {
            email: '',
            password: '',
            remember: true,
            loading: false,
            showPassword: false,
        };
    },
    components: {
        
    },
    computed:{
        loginTeacher(){
            return this.$route.name === 'login-teachers';
        },
        ...GlobalComputed 
    },
    methods: {
        ...GlobalMethods,
        login(){
            const self = this;

            let email = this.email,
                password = this.password,
                remember = this.remember;

            if (this.capRunning && this.isProduction){
                AppAPI.Notifications.getDeviceToken(response=>{
                    const deviceToken = response.token;
                    // window.console.log('EBM: Device token (' + deviceToken + ')');
                    self.loginAction(email, password, remember, deviceToken);
                }, error=>{
                    // console.error('EBM: ' + JSON.stringify(error));
                    // window.alert(JSON.stringify(error));
                    self.loginAction(email, password, remember);
                })
            }else{
                self.loginAction(email, password, remember);
            }
        },
        loginAction(email, password, remember = true, deviceToken=null){
             
            if (email && password){
                this.loading = true;
                let data = { email, password, remember, teacher: this.loginTeacher };
                if (deviceToken){
                    data.deviceToken = deviceToken;
                }

                this.$store.dispatch('loginAction', data).then(()=>{
                    this.loading = false;
                    this.$router.push({name: 'home'});
                }).catch(error=>{
                    this.loading = false;
                    if (error.response && (error.response.status === 401)){
                        if (error.response.data.message === 'SUSPENDED_ACCOUNT'){
                            this.showLoginMsg('Your account has been suspended, contact support.');
                        }else{
                            this.showLoginMsg('Email or Password is incorrect');
                        }
                    }else if (error.response && error.response.status === 422){
                        this.showLoginMsg('Data you entered is not valid');
                    }else{
                        this.showLoginMsg('Error occurred during signing you in');
                    }
                })
            }else{
                this.showLoginMsg("Inputs are required")
            }
        },
        showLoginMsg(msg){
            this.showMsg(msg, 'error', true);
        },
        closeMsg(){
            if (this.$store.state.globalModule.snackMsg.text){
                this.$store.dispatch('addSnackMsg', {show: false, text: '', msgBar: true});
            }
        },
    },
    watch: {
        email(){
            this.closeMsg();
        },
        password(){
            this.closeMsg();
        }
    },
    mounted(){
        

    }
}
</script>

<style lang="scss" scoped>

</style>